import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "1-show-affection-and-encouragement"
    }}>{`1. Show affection and encouragement`}</h1>
    <p>{`The teenage years are a time when young people try to create their own identity and want to be more independent. Yet they still want to feel connected to their families. It's a balancing act - the need to be connected and the need to be independent from others!`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m1/3.svg" alt="balancing act image" mdxType="SingleImage" />
    <h3 {...{
      "id": "how-do-you-help-your-teenager-with-this-balancing-act"
    }}>{`How do you help your teenager with this balancing act?`}</h3>
    <p>{`It's important to `}<strong parentName="p">{`regularly show`}</strong>{` your teenager that they are loved
and respected. `}<em parentName="p">{`But how do I do this now that they are no longer a child?`}</em></p>
    <p>{`There is no 'one way' to show love and affection to your teenager. What
works for one teenager, may not work for another. What suits one family
may not be appropriate for another family. It also varies with your
teenager's age and maturity.`}</p>
    <p>{`Choose ways to show affection that suit you and your teenager.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      